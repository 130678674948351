/* eslint-disable */
import React from 'react';
import { useEffect, useState, useLayoutEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate, useLocation, Link } from 'react-router-dom';
import { createBrowserHistory } from "history";
import NumberFormat from 'react-number-format';
import { Buffer } from "buffer";
import ConnectAccount from "./components/Account/ConnectAccount";
// import DisplayPane from "./components/displayPane/DisplayPane";
//import background from "./assets/images/background.jpg";
import pinftagram_logo from "./assets/images/pinftagramlogo.png";

import { Layout } from "antd";
import "./App.css";
import "antd/dist/antd.css";
import ChainSelector from "./components/ChainSelector";
import { useWeb3React } from "@web3-react/core";
import ReactLoading from "react-loading";
import styled from "styled-components";
import { ethers } from 'ethers';
// ,  providers
// import { useAlert } from 'react-alert';


import axios from 'axios';


import { create as ipfsHttpClient } from 'ipfs-http-client'
import InputDataDecoder from "ethereum-input-data-decoder";

import config from "./config.json";
import contractAddress from "./contracts/contract-address.json";
// import tokenAddress from "./contracts/token-address.json";
import WTNFT from './contracts/WTNFT.json';
import tiggerdash from "./TiggerDash.png"
import Pinfts88 from "./contracts/Pinfts88.json";

import "./css/Calculator.css";
import './css/dashboard.css';
import './css/normalize.css';
import './css/webflow.css';
import './css/tiggerdash.webflow.css';



export interface IApplicationProps { }

const { Header } = Layout; //, Footer
const mobile: boolean = false;
const styles = {
  layout: {

    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100vw",
    height: "100vh",
    overflow: "auto",
    fontFamily: "Sora, sans-serif"
  },
  header: {
    position: "fixed",
    zIndex: 1,
    width: "100%",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 20px",
    paddingTop: "0px",
    height: "130px"
  },
  headerRight: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    paddingRight: "10px",
    fontSize: "15px",
    fontWeight: "600"
  },
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    color: "#ffffff",
    marginTop: "100px",
    padding: "10px",
    overflow: "auto"
  },
  footer: {
    position: "fixed",
    textAlign: "center",
    width: "100%",
    bottom: "0",
    color: "white",
    backgroundColor: "transparent"
  },
  ul_menu: {
    listStyleType: "none",
    margin: "0",
    padding: "0",
    overflow: "hidden",
    backgroundColor: "white",
    paddingTop: mobile ? "0px" : "0px"
  },
  li_menu: {
    float: "right"
  },
  lia_menu: {
    display: "block",
    color: "black",
    textAlign: "center",
    padding: "0px",
    textDecoration: "none",
    cursor: "pointer",
    width: "120px",
    height: "40px",
    marginTop: "0px",
    fontSize: "20px"
  }

} as const;

const SBannerWrapper = styled.div`
  display: flex;
  align-items: center;
  
  width: 100%;
`;

const SBanner = styled.div`
   
  margin: 20px auto;
  width: 150px;
  height: 150px;
  background: url(${tiggerdash}) no-repeat;
  background-size: cover;
  background-position: center;
`;





const SLayout = styled.div`
  position: relative;
  width: 100%;
  /* height: 100%; */
  min-height: 100vh;
  text-align: center;
`;





const SContainer = styled.div`
  height: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  word-break: break-word;
`;

const SModalContainer = styled.div`
  width: 100%;
  position: relative;
  word-wrap: break-word;
`;

const SModalTitle = styled.div`
  margin: 1em 0;
  font-size: 20px;
  font-weight: 700;
`;

const SModalParagraph = styled.p`
  margin-top: 30px;
`;


const STable = styled(SContainer as any)`
  flex-direction: column;
  text-align: left;
`;

const SRow = styled.div`
  width: 100%;
  display: flex;
  margin: 6px 0;
`;

const SKey = styled.div`
  width: 30%;
  font-weight: 700;
`;

const SValue = styled.div`
  width: 70%;
  font-family: monospace;
`;



const Application: React.FunctionComponent<IApplicationProps> = (props) => {
  if (!window.Buffer) window.Buffer = Buffer;

  const [accountAddress, setAccountAddress] = useState("");
  const [accountBalance, setAccountBalance] = useState("");

  const [slidervalue, setSlidervalue] = useState(0);
  const [tokenPunt, setTokenPunt] = useState(0);
  const [dailyCompound, setDailyCompound] = useState(1.0229);
  const [myTokenBalance, setMyTokenBalance] = useState(0);
  const [projectedPrice, setProjectedPrice] = useState(50);
  const [myTokenValue, setMyTokenValue] = useState(0);
  const [tokenPuntMax, setTokenPuntMax] = useState(0);
  const [tokenPrice, setTokenPrice] = useState(50);
  const [circulatingSupply, setCirculatingSupply] = useState(100000);
  const [marketCap, setMarketCap] = useState(5000000);

  const [myTokenBalanceDays, setMyTokenBalanceDays] = useState(0);
  const [myTokenValueDays, setMyTokenValueDays] = useState(0);

  const [myWalletBalance, setMyWalletBalance] = useState('');


  const [myTokenBalanceTokenPunt, setMyTokenBalanceTokenPunt] = useState(0);
  const [myTokenValueTokenPunt, setMyTokenValueTokenPunt] = useState(0);

  const [myNewPriceBalance, setMyNewPriceBalance] = useState(0);
  const [myNewPriceValue, setMyNewPriceValue] = useState(0);

  const [wtnftContract, setWTNFTContract] = useState<any>(null);
  const [contractAddress1, setContractAddress1] = useState('');
  const [contractBalance, setContractBalance] = useState(0);
  const [contractTokens, setContractTokens] = useState(0);

  const [pinftsContract, setPinftsContract] = useState<any>(null);
  const [pinftsCount, setPinftsCount] = useState(0);
  const [pinfts, setPinfts] = useState<any>(null);

  const [minsSinceRebase, setMinsSinceRebase] = useState(0);
  const [cyclesSinceLaunch, setCyclesSinceLaunch] = useState(0);
  const [targettedRebaseSupply, setTargettedRebaseSupply] = useState(0);

  const [loadingData, setLoadingData] = useState(true);


  const [loading, setLoading] = useState(false);

  const [provider1, setProvider1] = useState<any>(null);
  const [httpUrl] = useState(config.httpurl);

  const [isAdmin, setIsAdmin] = useState(false);


  const [loaded, setLoaded] = useState(false)

  const [vipnfts, setVipnfts] = useState(0)


  const [loadingMessage, setLoadingMessage] = useState("Loading");
  const [bscUrl1, setBscUrl1] = useState("");

  const { account, provider } = useWeb3React();



  const convertpinft = (urlin: string) => {
    return urlin.replace("https://ipfs.infura.io", "https://pinftagram.infura-ipfs.io");
  }



  useEffect(() => {
    const fetchAccount = async () => {

      if (typeof (account) !== 'undefined' && typeof (provider) !== 'undefined') {
        // setLoadingData(true);
        // console.log(loadingData)
        setLoaded(false);

        setProvider1(provider);


        const selectedAddress = account.toLowerCase();
        await setAccountAddress(selectedAddress);

        const balance = await provider.getBalance(selectedAddress);
        const accountbalance = ethers.utils.formatEther(balance);
        setAccountBalance(accountbalance);

        const WTNFTContractAddress = contractAddress.WTNFT;
        setContractAddress1(WTNFTContractAddress);
        const wtnftcontract = new ethers.Contract(WTNFTContractAddress, WTNFT.abi, provider);
        setWTNFTContract(wtnftcontract);


        const mybnbbalance = await provider.getBalance(selectedAddress);

        setMyWalletBalance((Number(mybnbbalance) * (10 ** -18)).toString());

        const mytokenbalance = await wtnftcontract.balanceOf(selectedAddress);
        //  console.log(" balanceOf", mytokenbalance.toString() );
        const tokenbalance = mytokenbalance * (10 ** -18)
        await setMyTokenBalance(tokenbalance);

        await setMyTokenBalanceDays(tokenbalance);

        await setMyTokenBalanceTokenPunt(tokenbalance);

        await setMyNewPriceBalance(tokenbalance)

        const circulatingsupply = await wtnftcontract.getCirculatingSupply();
        await setCirculatingSupply(circulatingsupply * 10 ** -18);


        try {
          const res = await axios.get(`https://api.pancakeswap.info/api/v2/tokens/${WTNFTContractAddress}`)


          const resdata = res.data;

          const resdata1 = resdata.data;

          if (resdata1.price > 0) {
            const tokenprice = Math.round((Number(resdata1.price) * 10000)) / 10000;
            await setTokenPrice(tokenprice);

            const initialvalue = mytokenbalance * 10 ** -18 * tokenprice
            await setMyTokenValue(initialvalue);
            await setMyTokenValueDays(initialvalue);
            await setMyTokenValueTokenPunt(initialvalue);
            await setMyNewPriceValue(initialvalue);

            await setMarketCap(circulatingsupply * 10 ** -18 * tokenprice);

            await setTokenPuntMax(Math.round((Number(mybnbbalance) * (10 ** -18)) / resdata1.price_BNB));
            await setProjectedPrice(tokenprice);


          }

          const launchedAtTime = await wtnftcontract.launchedAtTime();
          // console.log(" launchedAtTime", launchedAtTime.toString() );
          // console.log('launchedAtTime',  converttimestamp(launchedAtTime.toString()));

          const lastRebaseTime1 = await wtnftcontract.lastRebaseTime();
          // console.log(" lastRebaseTime1 ", (lastRebaseTime1 ).toString()  );

          const currenttime = Math.floor(Date.now() / 1000);
          // console.log(" currenttime", currenttime.toString() );

          const cyclessincelaunch = (((currenttime - launchedAtTime) / 1800));
          const minssincerebase = (((currenttime - lastRebaseTime1) / 60))
          // console.log("cycles since launch",  cyclessincelaunch.toString());

          // console.log("time since rebase (minutes)",  minssincerebase.toString()    );
          const rebaserate = 1.000471889;
          const targettedrebasesupply = 100000 * ((rebaserate) ** cyclessincelaunch);
          // console.log("scheduled total supply",  targettedrebasesupply.toString()    );

          setMinsSinceRebase(minssincerebase);
          setCyclesSinceLaunch(cyclessincelaunch);
          setTargettedRebaseSupply(targettedrebasesupply);

          const pinftsAddress = contractAddress.Pinfts88;
          const pinftsContract2 = await new ethers.Contract(pinftsAddress, Pinfts88.abi, provider);


          let pinftstemp = [];
          let selectedbal1;
          let selecteduri;
          let meta;
          let nftcount = 0;
          let i = 3;
          selectedbal1 = await pinftsContract2.balanceOf(selectedAddress, i.toString());

          if (selectedbal1 > 0) {
            selecteduri = await pinftsContract2.uri(i.toString())
            meta = await axios.get(convertpinft(selecteduri));
            pinftstemp.push(meta.data);
            nftcount++;
          }

          i = 4;
          selectedbal1 = await pinftsContract2.balanceOf(selectedAddress, i.toString());

          if (selectedbal1 > 0) {
            selecteduri = await pinftsContract2.uri(i.toString())
            meta = await axios.get(convertpinft(selecteduri));
            pinftstemp.push(meta.data);
            nftcount++;
          }

          i = 5;
          selectedbal1 = await pinftsContract2.balanceOf(selectedAddress, i.toString());

          if (selectedbal1 > 0) {
            selecteduri = await pinftsContract2.uri(i.toString())
            meta = await axios.get(convertpinft(selecteduri));
            pinftstemp.push(meta.data);
            nftcount++;
          }
          i = 6;
          selectedbal1 = await pinftsContract2.balanceOf(selectedAddress, i.toString());

          if (selectedbal1 > 0) {
            selecteduri = await pinftsContract2.uri(i.toString())
            meta = await axios.get(convertpinft(selecteduri));
            pinftstemp.push(meta.data);
            nftcount++;
          }
          i = 7;
          selectedbal1 = await pinftsContract2.balanceOf(selectedAddress, i.toString());

          if (selectedbal1 > 0) {
            selecteduri = await pinftsContract2.uri(i.toString())
            meta = await axios.get(convertpinft(selecteduri));
            console.log(meta)
            pinftstemp.push(meta.data);
            nftcount++;

          }
          setVipnfts(nftcount);

          if (pinftstemp.length === 0) {
            pinftstemp = [];
            selectedbal1 = null;
            meta = null;
          }

          setPinfts(pinftstemp);



        } catch (error) {
          console.log(error);
        }

        await setLoadingData(false);

      }


      setLoaded(true);
    }

    fetchAccount();


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, provider])


  const convertduration = (duration: any) => {
    return new Date(duration * 1000).toISOString().substr(11, 8);
  }
  const converttimestamp = (timestamp: any) => {
    const date1 = new Date((timestamp - (3 * 60 * 60)) * 1000);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const year = date1.getFullYear();
    const month = months[date1.getMonth()];
    const date = date1.getDate();
    const hour = date1.getHours();
    const min = date1.getMinutes();
    const sec = date1.getSeconds();
    const time = `${date}   ${month}  ${year} ${hour} : ${min} :${sec}`;
    return time;
  }

  // move slider
  const handleChange = async (event: any) => {

    const numdays = event.target.value;
    const numdaysplus1 = Number(numdays);
    const dailycompound = Number(dailyCompound);

    await setSlidervalue(numdays);
    await setMyTokenBalanceDays(myTokenBalance * (dailycompound ** numdaysplus1));
    await setMyTokenValueDays(myTokenValue * (dailycompound ** numdaysplus1));

    // token punt  
    const mynewtokenBalance = Number(myTokenBalance) + Number(tokenPunt)
    const mynewtokenValue = Number(myTokenValue) + (Number(tokenPunt) * Number(tokenPrice));
    await setMyTokenBalanceTokenPunt(mynewtokenBalance * (dailycompound ** numdaysplus1))
    await setMyTokenValueTokenPunt(mynewtokenValue * (dailycompound ** numdaysplus1))


    // projected price
    const mynewpriceBalance1 = Number(mynewtokenBalance)
    const mynewpriceValue1 = Number(mynewtokenBalance) * Number(projectedPrice);
    await setMyNewPriceBalance(mynewpriceBalance1 * (dailycompound ** numdaysplus1))
    await setMyNewPriceValue(mynewpriceValue1 * (dailycompound ** numdaysplus1))

  };




  const handleChangeTokenPunt = async (event: any) => {
    setTokenPunt(event.target.value)
    const numdays: number = slidervalue;
    const numdaysplus1 = Number(numdays);
    const dailycompound = Number(dailyCompound);

    // token punt
    const mynewtokenBalance = Number(myTokenBalance) + Number(event.target.value)
    const mynewtokenValue = Number(myTokenValue) + (Number(event.target.value) * Number(tokenPrice));
    setMyTokenBalanceTokenPunt(mynewtokenBalance * (dailycompound ** numdaysplus1))
    setMyTokenValueTokenPunt(mynewtokenValue * (dailycompound ** numdaysplus1))

    // projected price
    const mynewpriceBalance1 = Number(mynewtokenBalance)
    const mynewpriceValue1 = Number(mynewtokenBalance) * Number(projectedPrice);
    setMyNewPriceBalance(mynewpriceBalance1 * (dailycompound ** numdaysplus1))
    setMyNewPriceValue(mynewpriceValue1 * (dailycompound ** numdaysplus1))

  }

  const handleChangeTokenPuntMax = async () => {
    setTokenPunt(tokenPuntMax);
    const numdays: number = slidervalue;
    const numdaysplus1 = Number(numdays);
    const dailycompound = Number(dailyCompound);

    // token punt
    const mynewtokenBalance = Number(myTokenBalance) + Number(tokenPuntMax)
    const mynewtokenValue = Number(myTokenValue) + (Number(tokenPuntMax) * Number(tokenPrice));
    setMyTokenBalanceTokenPunt(mynewtokenBalance * (dailycompound ** numdaysplus1))
    setMyTokenValueTokenPunt(mynewtokenValue * (dailycompound ** numdaysplus1))

    // projected price
    const mynewpriceBalance1 = Number(mynewtokenBalance)
    const mynewpriceValue1 = Number(mynewtokenBalance) * Number(projectedPrice);
    setMyNewPriceBalance(mynewpriceBalance1 * (dailycompound ** numdaysplus1))
    setMyNewPriceValue(mynewpriceValue1 * (dailycompound ** numdaysplus1))

  }



  const handleProjectedPrice = async (event: any) => {
    setProjectedPrice(event.target.value);
    const numdays: number = slidervalue;
    const numdaysplus1 = Number(numdays);
    const dailycompound = Number(dailyCompound);


    // token punt
    const mynewtokenBalance = Number(myTokenBalanceTokenPunt)
    const mynewtokenValue = (Number(myTokenBalanceTokenPunt) * Number(tokenPrice));
    setMyTokenBalanceTokenPunt(mynewtokenBalance * (dailycompound ** numdaysplus1))
    setMyTokenValueTokenPunt(mynewtokenValue * (dailycompound ** numdaysplus1))

    // projected price
    const mynewpriceBalance1 = Number(mynewtokenBalance)
    const mynewpriceValue1 = Number(mynewtokenBalance) * Number(event.target.value);
    setMyNewPriceBalance(mynewpriceBalance1 * (dailycompound ** numdaysplus1))
    setMyNewPriceValue(mynewpriceValue1 * (dailycompound ** numdaysplus1))


  }

  const handleProjectedPriceCurrent = async () => {
    setProjectedPrice(tokenPrice);

    const numdays: number = slidervalue;
    const numdaysplus1 = Number(numdays);
    const dailycompound = Number(dailyCompound);

    // token punt
    const mynewtokenBalance = Number(myTokenBalanceTokenPunt)
    const mynewtokenValue = (Number(myTokenBalanceTokenPunt) * Number(tokenPrice));
    await setMyTokenBalanceTokenPunt(mynewtokenBalance * (dailycompound ** numdaysplus1))
    await setMyTokenValueTokenPunt(mynewtokenValue * (dailycompound ** numdaysplus1))


    // projected price
    const mynewpriceBalance1 = Number(mynewtokenBalance)
    const mynewpriceValue1 = Number(mynewtokenBalance) * Number(tokenPrice);
    await setMyNewPriceBalance(mynewpriceBalance1 * (dailycompound ** numdaysplus1))
    await setMyNewPriceValue(mynewpriceValue1 * (dailycompound ** numdaysplus1))

  }


  if (loaded !== true) {
    return <>
      <Layout style={styles.layout}>
        <Header style={styles.header}>
          {/* <Logo /> */}
          <div style={styles.headerRight}>
            <ChainSelector />
            <ConnectAccount />
          </div>
        </Header>
        <br /><br /><br /><br /> <br />
        <div style={{ marginTop: "400px" }}>
          <ReactLoading type="balls" color="#000000" height={100} width={100} />
        </div>
      </Layout>

      {/* */}

    </>
  }

  return (
    <Layout style={styles.layout}>
      <Header style={styles.header} >
        {/* <Logo /> */}

        <div style={styles.headerRight}>
          <ChainSelector />
          <ConnectAccount />
        </div>
      </Header >

      <div style={{ display: !loading ? "block" : "block", opacity: !loading ? "1" : "0.2" }}>
        <>
          {loading &&
            (
              <div style={{ height: "100vh" }}>
                <br /> <br /><br /><br /><br />
                <br /> <ReactLoading type="balls" color="#000000" height={100} width={100} />
                <br />
                <span>{loadingMessage}</span>
              </div>
            )



          }


          <>


            <br /><br /><br /><br /><br /><br /><br />

            <SBannerWrapper>
              <SBanner />
            </SBannerWrapper>
            <div  >
              <h1 className="heading">White Tigger Finance (WTF)</h1>
              <p className="paragraph">
                <a
                  href={`https://bscscan.com/token/${contractAddress1}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "blue" }}
                >
                  {contractAddress1}
                </a></p>
              {/* <p className="paragraph">Calculator: Estimate your returns</p> */}
            </div>
            <br />
            {vipnfts > 0 &&
              <table style={{ margin: "auto" }}>
                <tbody>
                  <tr>

                    <td>
                      <img alt="imagetype" src={convertpinft(pinfts[0].image)}
                        style={{
                          margin: "auto", cursor: "pointer", padding: "3px", display: "block",
                          width: "200px", maxHeight: "200px"
                        }} aria-hidden="true" />
                      <h5 style={{ textAlign: "center", width: "100%" }}>{pinfts[0].name}</h5>
                    </td>

                    {pinfts.length > 1 &&
                      <td>
                        <img alt="imagetype" src={convertpinft(pinfts[1].image)}
                          style={{
                            margin: "auto", cursor: "pointer", padding: "3px", display: "block",
                            width: "200px", maxHeight: "200px"
                          }} aria-hidden="true" />
                        <h5 style={{ textAlign: "center", width: "100%" }}>{pinfts[1].name}</h5>
                      </td>
                    }
                    {pinfts.length > 2 &&
                      <td>
                        <img alt="imagetype" src={convertpinft(pinfts[2].image)}
                          style={{
                            margin: "auto", cursor: "pointer", padding: "3px", display: "block",
                            width: "200px", maxHeight: "200px"
                          }} aria-hidden="true" />
                        <h5 style={{ textAlign: "center", width: "100%" }}>{pinfts[2].name}</h5>
                      </td>
                    }
                    {pinfts.length > 3 &&
                      <td>
                        <img alt="imagetype" src={convertpinft(pinfts[3].image)}
                          style={{
                            margin: "auto", cursor: "pointer", padding: "3px", display: "block",
                            width: "200px", maxHeight: "200px"
                          }} aria-hidden="true" />
                        <h5 style={{ textAlign: "center", width: "100%" }}>{pinfts[3].name}</h5>
                      </td>
                    }
                    {pinfts.length > 4 &&
                      <td>
                        <img alt="imagetype" src={convertpinft(pinfts[4].image)}
                          style={{
                            margin: "auto", cursor: "pointer", padding: "3px", display: "block",
                            width: "200px", maxHeight: "200px"
                          }} aria-hidden="true" />
                        <h5 style={{ textAlign: "center", width: "100%" }}>{pinfts[4].name}</h5>
                      </td>
                    }

                  </tr>
                </tbody>
              </table>
            }

            <div className="calculator">


              <h1 className="subtitle">WTF APY 388,888.88%</h1>
              <div className="rect" />


              <div className="sub01">
                <div className="set01">
                  <div className="set01-a">
                    <h1 className="set-a-title">WTF Price</h1>
                    <div className="set-a-input">
                      <p className="paragraph-2">$<NumberFormat value={tokenPrice} displayType='text' thousandSeparator=',' decimalScale={3} /></p>
                    </div>
                  </div>
                </div>

                <div className="set01">
                  <div className="set01-a">
                    <h1 className="set-a-title">Market Cap</h1>
                    <div className="set-a-input">
                      <p className="paragraph-2">$<NumberFormat value={marketCap} displayType='text' thousandSeparator=',' decimalScale={3} /></p>
                    </div>
                  </div>
                </div>
                <div className="set01">
                  <div className="set01-a">
                    <h1 className="set-a-title">Circulating</h1>
                    <div className="set-a-input">
                      <p className="paragraph-2">WTF<NumberFormat value={circulatingSupply} displayType='text' thousandSeparator=',' decimalScale={0} /></p>
                    </div>
                  </div>
                </div>
              </div>



              <h1 className="subtitle">Rebase Schedule</h1>
              <div className="rect" />
              <div className="sub01">
                <div className="set01">
                  <div className="set01-a">
                    <h1 className="set-a-title"  >Last</h1>
                    <div className="set-a-input"  >
                      <p className="paragraph-2"><NumberFormat value={minsSinceRebase} displayType='text' thousandSeparator=',' decimalScale={0} /> mins</p>
                    </div>
                  </div>
                </div>

                <div className="set01">
                  <div className="set01-a">
                    <h1 className="set-a-title"  >Cycle </h1>
                    <div className="set-a-input"   >
                      <p className="paragraph-2"><NumberFormat value={cyclesSinceLaunch} displayType='text' thousandSeparator=',' decimalScale={0} /></p>
                    </div>
                  </div>
                </div>
                <div className="set01">
                  <div className="set01-a" >
                    <h1 className="set-a-title"  >Targeted</h1>
                    <div className="set-a-input"  >
                      <p className="paragraph-2"  >WTF<NumberFormat value={targettedRebaseSupply} displayType='text' thousandSeparator=',' decimalScale={0} /></p>
                    </div>
                  </div>
                </div>
              </div>


              <h1 className="subtitle">My Current Portfolio</h1>
              <div className="rect" />
              <div className="sub02">
                <div className="set01">
                  <div className="set01-a">
                    <h1 className="set-a-title">My Tokens</h1>
                    <div className="set-a-input">
                      <p className="paragraph-2">WTF<NumberFormat value={myTokenBalance} displayType='text' thousandSeparator=',' decimalScale={3} /></p>
                    </div>
                  </div>
                </div>
                <div className="set01">
                  <div className="set01-a">
                    <h1 className="set-a-title">Holdings</h1>
                    <div className="set-a-input">
                      <p className="paragraph-2">$<NumberFormat value={myTokenValue} displayType='text' thousandSeparator=',' decimalScale={3} /></p>
                    </div>
                  </div>
                </div>
                <div className="set01">
                  <div className="set01-a">
                    <h1 className="set-a-title">Wallet</h1>
                    <div className="set-a-input">
                      <p className="paragraph-2">BNB<NumberFormat value={myWalletBalance} displayType='text' thousandSeparator=',' decimalScale={3} /></p>
                    </div>
                  </div>
                </div>
              </div>

              <br />
              <h1 className="subtitle">Projected {slidervalue} Days</h1>

              <div className="calc_range  ">
                <input
                  type="range"
                  min="0"
                  max="365"
                  defaultValue="0"
                  className="scroll-circle"
                  id="myRange"
                  onChange={handleChange}

                />
              </div>


              <div className="sub03">
                <div className="set03" >
                  <div className="set01-a">
                    <h1 className="set-a-title">Tokens</h1>
                    <div className="set-a-input">
                      <p className="paragraph-2">WTF<NumberFormat value={myTokenBalanceDays} displayType='text' thousandSeparator=',' decimalScale={3} /></p>
                    </div>
                  </div>
                </div>
                <div className="set03">
                  <div className="set01-a">
                    <h1 className="set-a-title">Holdings</h1>
                    <div className="set-a-input">
                      <p className="paragraph-2">$<NumberFormat value={myTokenValueDays} displayType='text' thousandSeparator=',' decimalScale={3} /></p>
                    </div>
                  </div>
                </div>
              </div>

              <h1 className="subtitle">If I purchase {tokenPunt} White Tigger Token @ $ <NumberFormat value={tokenPrice} displayType='text' thousandSeparator=',' decimalScale={3} /></h1>
              <div className="rect" />
              <div className="sub04">
                <div className="set01">
                  <div className="set01-a">
                    <h1 className="set-a-title">Buy WTF</h1>
                    <div className="set-b-input">

                      <div className="div_input">
                        <div style={{ height: "6px" }} />
                        <div className="calc_input" >
                          <div className="input">

                            <input
                              id="tokenpunt"

                              onChange={handleChangeTokenPunt}
                              value={tokenPunt}
                            />
                          </div>
                        </div>

                        <button type='button' style={{ backgroundColor: "555555", float: "right", borderRadius: "6px", margin: "6px" }} onClick={handleChangeTokenPuntMax}>Max</button>

                      </div>
                    </div>
                  </div>
                </div>
                <div className="set01">
                  <div className="set01-a">
                    <h1 className="set-a-title">Tokens</h1>
                    <div className="set-b-input">
                      <p className="paragraph-2">WTF<NumberFormat value={myTokenBalanceTokenPunt} displayType='text' thousandSeparator=',' decimalScale={3} /></p>
                    </div>
                  </div>
                </div>
                <div className="set01">
                  <div className="set01-a">
                    <h1 className="set-a-title">Holdings</h1>
                    <div className="set-b-input">
                      <p className="paragraph-2">$<NumberFormat value={myTokenValueTokenPunt} displayType='text' thousandSeparator=',' decimalScale={3} /></p>
                    </div>
                  </div>
                </div>
              </div>

              <h1 className="subtitle">Projected Price White Tigger Token @ ${projectedPrice} </h1>
              <div className="rect" />
              <div className="sub04">
                <div className="set01">
                  <div className="set01-a">
                    <h1 className="set-a-title">WTF Price</h1>
                    <div className="set-b-input">
                      <div className="div_input">
                        <div style={{ height: "6px" }} />
                        <div className="calc_input">
                          <div className="input">

                            <input
                              id="projectedprice"
                              placeholder="Projected price"
                              onChange={handleProjectedPrice}

                              value={projectedPrice}
                            />
                          </div>
                        </div>
                        <button type='button' style={{ backgroundColor: "555555", float: "right", borderRadius: "6px", margin: "6px" }} onClick={handleProjectedPriceCurrent}>Current</button>

                      </div>


                    </div>
                  </div>
                </div>
                <div className="set01">
                  <div className="set01-a">
                    <h1 className="set-a-title">Tokens</h1>
                    <div className="set-b-input">
                      <p className="paragraph-2">WTF<NumberFormat value={myNewPriceBalance} displayType='text' thousandSeparator=',' decimalScale={3} /></p>
                    </div>
                  </div>
                </div>
                <div className="set01">
                  <div className="set01-a">
                    <h1 className="set-a-title">Holdings</h1>
                    <div className="set-b-input">
                      <p className="paragraph-2">$<NumberFormat value={myNewPriceValue} displayType='text' thousandSeparator=',' decimalScale={3} /></p>
                    </div>
                  </div>
                </div>
              </div>


              <br /> <br /> <br />



            </div>







          </>
          )
        </>
      </div>


    </Layout >
  );
}

export const Logo = () => {
  return (
    <div style={{ paddingTop: "15px", width: "200px" }}>
      <img src={pinftagram_logo} alt="pinftagram_logo" width="188px" />
    </div>
  )
};

export default Application;
